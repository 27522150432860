import { defineStore } from 'pinia'
// import {getRoleRouter} from "@/api/home/permission/menus";
// import {logout} from '@/api/index';
export const useCounterStore = defineStore('counter', {
  state: () => {
    return {
      menuList: [],//菜单信息
      roles: [],//角色信息
      menuRouter: [],//路由信息
      roleIds: [],//角色Id
      OEM:[
        {id:90,name:'测试OEM'},
        {id:75,name:'金链智通'},
        {id:37,name:'金鼎订单贷'},
        {id:49,name:'顺隆'},
        {id:81,name:'蘑菇多多'},
        {id:50,name:'鲸致科技'},
        {id:36,name:'先锋贷'},
        {id:35,name:'亿峰科技'},
        {id:25,name:'易云订单链'},
        {id:19,name:'右吉函夏'},
      ],//OEM商用户
      status:[
        {id:6,name:'已审核'},
        {id:5,name:'审核中'},
        {id:1,name:'待审核'},
        {id:3,name:'已驳回'}
      ],
      OEMType:[
        {id:1,name:'企业'},
        {id:1,name:'个体工商户'},
      ],
      FunderList:[
        {id:84,name:'天象（测试）2'},
        {id:56,name:'捷诚小贷'},
        {id:44,name:'天象（测试）'},
        {id:22,name:'粤普小额再贷'},
        {id:21,name:'华润银行'},
      ],
      statusList:[
        { label: "审核中", value: "0" },
        { label: "通过", value: "1" },
        { label: "驳回", value: "2" },
      ]
    }
  },
  // 持久化设置
  persist: {
    storage: sessionStorage,	//修改存储位置
    key: 'permissions',	//设置存储的key,在这里是存在sessionStorage时的键
    paths: ['menuList', 'hasRouter', `roles`, 'roleIds'],//指定要持久化的字段，menuRouter不需要缓存。因为每次路由跳转我们都可以重新获取
  },
  getters: {
    //设置路由：data-->后端传入的路由信息
    setMenuRouter(data) {
      data.forEach(item => {
        //定义一个对象-->routerInfo格式化后端传入的路由信息
        let routerInfo = {
          path: item.path,
          name: item.name,
          meta: item.name,
          component: () => import(`@/views/${item.linkUrl}`),
        }
        this.menuRouter.push(routerInfo)//加入到home子路由下
      })
    },
    setRoles(data) {
      this.roles = data
      this.roleIds = data.map(item => {
        return item.id
      })
    },
  },
  actions: {
    //菜单
    setMenuList(data) {
      this.menuList = data
    },
  },
})
