<template>
    <div>
        <template v-for="item in Props.menuList" :key="item.path">
            <!--      分为两种方式渲染：有子菜单和没有子菜单-->
            <el-sub-menu :index="item.path" v-if="item.nodeType == 1">
                <template #title>
                    <el-icon><Grid /></el-icon>
                    <span>{{ item.name }}</span>
                </template>
                <!--        有子菜单的继续遍历（递归）-->
                <menuTree :menuList="item.children"></menuTree>
            </el-sub-menu>
            <!--      没有子菜单-->
            <el-menu-item :index="item.path" v-if="item.nodeType == 2">
                <el-icon><Operation /></el-icon>
                <span>{{ item.name }}</span>
            </el-menu-item>
        </template>
    </div>
</template>
<script setup>
// 接收父组件传递的参数
// 定义props
import { defineProps } from "vue";
import menuTree from "../components/menuView.vue";
const Props = defineProps({
    menuList: {
    type: Array
},
})

</script>