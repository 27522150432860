<template>
  <div>
    <el-container>
      <div class="left">
        <el-scrollbar height="100vh" style="background-color: var(--bg-color) ;">
          <el-aside width="10vw" style="background-color: var(--bg-color) ;" class="aside">
            <el-menu active-text-color="#ffd04b" background-color="#545c64" class="el-menu-vertical-demo"
              :default-active="$route.path" text-color="var(--text-color)" :collapse="collapse" :router="true" @select="select" :unique-opened="true">
              <div class="title" style="display: flex;align-items: center;color: var(--text-color);background-color: var(--bg-color);padding:10px ;">
                <img src="https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80" alt="">
                <p>后台管理系统</p>
              </div>
              <menuTree  :menuList="menuList"></menuTree>
            </el-menu>
          </el-aside>
        </el-scrollbar>
      </div>

      <el-container class="container">
        <el-page-header style="" class="header" @back="goback">
          <template #title>
            <el-breadcrumb separator="/">
              <el-breadcrumb-item @click="goback">
                {{ page.one }}
              </el-breadcrumb-item>
            </el-breadcrumb>
          </template>
          <span @click="toggle">切换主题</span>
          <img src="https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80" alt="">
          <el-dropdown :hide-on-click="false">
            <span class="el-dropdown-link">
              admin<el-icon class="el-icon--right"><arrow-down /></el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="exit"><el-icon>
                    <SwitchButton />
                  </el-icon>退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-page-header>
        <el-main class="component">
          <el-scrollbar class="scrollbar">
            <router-view></router-view>
          </el-scrollbar>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import menuTree from "../../components/menuView";//渲染菜单的组件
import { useCounterStore } from "../../store/index";
const collapse = ref(false)
const router = useRouter();

let menuList = useCounterStore().menuList;//获取pinia的缓存的菜单数据
const style = ref(1)
const toggle = () => {
    if (style.value == 1) {
        document.documentElement.setAttribute('theme', 'light')
        style.value = 2
    }else{
      document.documentElement.setAttribute('theme', 'dark')
        style.value = 1
    }
}

import {
  reactive,
  onMounted, ref, watch
} from "vue";
import { ElMessage } from "element-plus";
const page = reactive({
  one: "首页",
  two: "",
  three: "",
})
let params = ref(router.currentRoute.value.path)
onMounted(() => {
  let index = menuList.findIndex((item) =>item.path == params.value)
  if (index== -1) {
    index = menuList.forEach((item) =>{
      if (item.children) {
        item.children.forEach((item2) =>{
          if (item2.path == params.value) {
            page.one = item2.name;
          }else if(item2.children){
            item2.children.forEach((item3) =>{
              if (item3.path == params.value) {
                page.one = item3.name;
              }
            })
          }
        })
      }
    })
  }else{
  page.one = menuList[index].name;
  }
})
function select() {
//  let index = menuList.findIndex((item) =>item.path == params)
//   if (index== -1) {
//     index = menuList.forEach((item) =>{
//       if (item.children) {
//         item.children.forEach((item2) =>{
//           if (item2.path == params) {
//             page.one = item2.name;
//           }else if(item2.children){
//             item2.children.forEach((item3) =>{
//               if (item3.path == params) {
//                 page.one = item3.name;
//               }
//             })
//           }
//         })
//       }
//     })
//   }else{
//   page.one = menuList[index].name;
//   }
}
watch(
  () => router.currentRoute.value.path,
  (newVal) =>{
    console.log(newVal);
    let index = menuList.findIndex((item) =>item.path == newVal)
  if (index== -1) {
    index = menuList.forEach((item) =>{
      if (item.children) {
        item.children.forEach((item2) =>{
          if (item2.path == newVal) {
            page.one = item2.name;
          }else if(item2.children){
            item2.children.forEach((item3) =>{
              if (item3.path == newVal) {
                page.one = item3.name;
              }
            })
          }
        })
      }
    })
  }else{
  page.one = menuList[index].name;
  }
  }
)
function goback() {
  router.go(-1);
}

function exit() {
  ElMessage.success("退出成功");
  setTimeout(() => {
    sessionStorage.clear();
   router.push("/login");
  },1500)

}
</script>

<style lang="less" scoped>
:deep(.el-menu-item-group__title) {
  display: none !important;
}

:deep(.scrollbar) {
  height: calc(100vh - 140px);
  overflow-x: hidden;
}

:deep(.el-menu) {
  border: none;
}

.aside {
  border: none;
}

// .el-aside::-webkit-scrollbar {
// display: none;
// }
.left {
  width: 10vw;
  z-index: 2000;
  height: 100vh;
  position: fixed;
  border-right: 1px solid var(--border-color);
}

img {
  width: 45px;
  margin: 0 10px;
}

.container {
  background-color: var(--bg-color);
  display: flex;
  // height: 100vh;
  flex-direction: column;
  color: var(--text-color) !important;
  padding-left: 10%;

  .component {
    padding: 100px 40px 40px 40px;

    background-color: var(--bg-color);
    color: var(--text-color) !important;

  }

  .header {
    text-align: right;
    font-size: 16px;
    padding: 0 20px;
    height: 60px;
    position: fixed;
    z-index: 999;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    background-color: var(--bg-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
    color: var(--text-color) !important;

    :deep(.el-divider) {
      display: none;
    }

    :deep(.el-breadcrumb__inner) {
      color: var(--text-color) !important;
    }

    :deep(.el-page-header__main) {
      border: none;
      display: flex;
      align-items: center;
      margin: 40px;

      .el-dropdown {
        border: none;

        span {
          color: var(--text-color) !important;

        }
      }
    }


  }
}

:deep(.el-dropdown-menu) {
  background-color: var( --content-color) !important;
}

:deep(.el-menu-item:hover) {
  background-color: var(--bg-color) !important;
}

:deep(.el-sub-menu__title) {
  text-align: left;
  user-select: none;
  background-color: var(--bg-color) !important;

}

:deep(.el-menu-item) {
  text-align: left;
  user-select: none;
  // padding-right: 53px !important;
  background-color: var(--bg-color) !important;
}

:deep(.el-menu-item.is-active) {
  text-align: left;
  user-select: none;
  // padding-right: 53px !important;
  color: var(--active-text-color) !important;
  background-color: var(--active-bg-color) !important;
}</style>