import Layout from '../views/home/indexView.vue'
export const allroutes = [
    // 首页
    {
        path: '/',
        name: 'home',
        component: () => import('../views/home/indexView.vue'),//首页
        redirect: '/homePage',
        children: [
            {
                path: '/homePage',
                name: 'homePage',
                component: () => import('../views/pages/homePage.vue'),//首页
            }
        ]
    },
    // 登录
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/loginView') //登录
    },
    // 开户管理
    {
        path: '/AccountManage',
        name: 'AccountManage',
        component: Layout,
        children: [
            {
                path: '/applyAccount',
                name: 'applyAccount',
                component: () => import('../views/pages/AccountManage/applyAccount.vue')//申请账户列表
            },
            {
                path: '/CooperativeBank',
                name: 'CooperativeBank',
                component: () => import('../views/pages/AccountManage/CooperativeBank.vue')//合作银行列表  
            },
            {
                path: '/successAccount',
                name: 'successAccount',
                component: () => import('../views/pages/AccountManage/successAccount.vue')//成功开户列表  
            },
            {
                path: '/withdrawalDetails',
                name: 'withdrawalDetails',
                component: () => import('../views/pages/AccountManage/withdrawalDetails.vue')//充值提现明细列表  
            },
        ]
    },
    // 客户管理
    {
        path: '/customerManagement',
        name: 'customerManagement',
        component: Layout,
        children: [
            {
                path: '/customerManagement',
                name: 'customerManagement',
                component: () => import('../views/pages/customerManagement/customerManagement.vue')//客户管理
            },
            {
                path: '/dataAnalysis',
                name: 'dataAnalysis',
                component: () => import('../views/pages/customerManagement/dataAnalysis.vue')//客户数据分析
            },
        ]
    },
    // 资质审核
    {
        path: '/QualificationReview',
        name: 'QualificationReview',
        component: Layout,
        children: [
            {
                path: '/accountManagement',
                name: 'accountManagement',
                component: () => import('../views/pages/QualificationReview/accountManagement.vue')//账户管理
            },
            {
                path: '/CustomerDataAuthorization',
                name: 'CustomerDataAuthorization',
                component: () => import('../views/pages/QualificationReview/CustomerDataAuthorization.vue')//客户数据授权
            },
            {
                path: '/CreditAuthorizationInquiry',
                name: 'CreditAuthorizationInquiry',
                component: () => import('../views/pages/QualificationReview/CreditAuthorizationInquiry.vue')//信用授权查询
            },
            {
                path: '/RealNameAuthenticationStatus',
                name: 'RealNameAuthenticationStatus',
                component: () => import('../views/pages/QualificationReview/CustomerDataAuthorization.vue')//实名认证情况
            },
        ]
    },
    // 交易数据
    {
        path: '/StoreTransactions',
        name: 'StoreTransactions',
        component: Layout,
        children: [
            {
                path: '/StoreTransactions',
                name: 'StoreTransactions',
                component: () => import('../views/pages/transactionData/StoreTransactions.vue')//店铺交易分析
            },
        ]
    },
    // 渠道商管理
    {
        path: '/ChannelMerchants',
        name: 'ChannelMerchants',
        component: Layout,
        children: [
            {
                path: '/ChannelMerchantList',
                name: 'ChannelMerchantList',
                component: () => import('../views/pages/ChannelMerchants/ChannelMerchantList.vue')//渠道商列表
            },
            {
                path: '/ServiceFeeRatio',
                name: 'ServiceFeeRatio',
                component: () => import('../views/pages/ChannelMerchants/ServiceFeeRatio.vue')//服务费比例
            },
            {
                path: '/ServiceFeeStatistics',
                name: 'ServiceFeeStatistics',
                component: () => import('../views/pages/ChannelMerchants/ServiceFeeStatistics.vue')//服务费统计
            },
            {
                path: '/WithdrawalApplication',
                name: 'WithdrawalApplication',
                component: () => import('../views/pages/ChannelMerchants/WithdrawalApplication.vue')//提现申请
            },
        ]
    },

    //银行公司管理
    {
        path: '/BankingCompanyManagement',
        name: 'BankingCompanyManagement',
        component: Layout,
        children: [
            {
                path: '/BankingCompanyManagement',
                name: 'BankingCompanyManagement',
                component: () => import('../views/pages/BankingCompanyManagement.vue')//银行公司管理
            }
        ]

    },
    //通知管理
    {
        path: '/noticeManagement',
        name: 'noticeManagement',
        component: Layout,
        children: [
            {
                path: '/noticeManagement',
                name: 'noticeManagement',
                component: () => import('../views/pages/noticeManagement.vue')//通知管理
            }
        ]

    },
    //放款管理
    {
        path: '/LoanManagement',
        name: 'LoanManagement',
        component: Layout,
        children: [
            {
                path: '/LoanManagement',
                name: 'LoanManagement',
                component: () => import('../views/pages/LoanManagement.vue')//放款管理
            },
        ]
    },
    //还款管理
    {
        path: '/RepaymentManagement',
        name: 'RepaymentManagement',
        component: Layout,
        children: [
            {
                path: '/RepaymentManagement',
                name: 'RepaymentManagement',
                component: () => import('../views/pages/RepaymentManagement.vue')//还款管理
            },
        ]

    },
    //贷款申请管理
    {
        path: '/loanApplication',
        name: 'loanApplication',
        component: Layout,
        children: [
            {
                path: '/loanApplication',
                name: 'loanApplication',
                component: () => import('../views/pages/loanApplication/loanApplication.vue')//贷款申请管理
            },
        ]

    },
    // 合同第三方管理
    {
        path: '/contractManagement',
        component: Layout,
        name: 'contractManagement',
        children: [
            {
                path: '/contractManagement',
                name: 'contractManagement',
                component: () => import('../views/pages/contractManagement/contractManagement.vue')//合同日志
            },
            {
                path: '/ContractList',
                name: 'ContractList',
                component: () => import('../views/pages/contractManagement/ContractList.vue')//合同列表
            },
            {
                path: '/CustomerList',
                name: 'CustomerList',
                component: () => import('../views/pages/contractManagement/CustomerList.vue')//客户列表
            },
        ]
    },
    // OEM管理
    {
        path: '/OEMMangagement',
        component: Layout,
        name: 'OEMMangagement',
        children: [
            {
                path: '/OEMMangagement',
                name: 'OEMMangagement',
                component: () => import('../views/pages/OEMMangagement/OEMMangagement.vue')//OEM管理
            },
            {
                path: '/OEMmessage',
                name: 'OEMmessage',
                component: () => import('../views/pages/OEMMangagement/OEMmessage.vue')//OEM留言
            },
            {
                path: '/OEMSubmitMaterials',
                name: 'OEMSubmitMaterials',
                component: () => import('../views/pages/OEMMangagement/OEMSubmitMaterials.vue')//OEM提交资料
            },
        ]
    },
    // 短信管理
    {
        path: '/messageManagement',
        component: Layout,
        name: 'messageManagement',
        children: [
            {
                path: '/SMSInterface',
                name: 'SMSInterface',
                component: () => import('../views/pages/messageManagement/SMSInterface.vue')//短信接口
            },
            {
                path: '/SMSMessages',
                name: 'SMSMessages',
                component: () => import('../views/pages/messageManagement/SMSMessages.vue')//短信消息库
            },
        ]
    },
    // 风险管控
    {
        path: '/RiskManagement',
        component: Layout,
        name: 'RiskManagement',
        children: [
            {
                path: '/RiskManagement',
                name: 'RiskManagement',
                component: () => import('../views/pages/RiskManagement/RiskManagement.vue')//风险管理
            },
            {
                path: '/phoneMangement',
                name: 'phoneMangement',
                component: () => import('../views/pages/RiskManagement/phoneMangement.vue')//手机号管理
            },
        ]
    },
    // 分销管理
    {
        path: '/distributionManagement',
        component: Layout,
        name: 'distributionManagement',
        children: [
            {
                path: '/EmployeeList',
                name: 'EmployeeList',
                component: () => import('../views/pages/distributionManagement/EmployeeList.vue')//员工列表
            },
            {
                path: '/WithdrawalRecords',
                name: 'WithdrawalRecords',
                component: () => import('../views/pages/distributionManagement/WithdrawalRecords.vue')//提现记录
            },
            {
                path: '/TeamList',
                name: 'TeamList',
                component: () => import('../views/pages/distributionManagement/TeamList.vue')//团队列表
            },
        ]
    },
    // 资金管理
    {
        path: '/MoneyManagement',
        component: Layout,
        name: 'MoneyManagement',
        children: [
            {
                path: '/WithdrawalView',
                name: 'WithdrawalView',
                component: () => import('../views/pages/MoneyManagement/WithdrawalView/WithdrawalView.vue'),//提现
            },
            {
                path: '/AccountOverview',
                name: 'AccountOverview',
                component: () => import('../views/pages/MoneyManagement/AccountOverview/AccountOverview.vue')//账户总览
            },
            {
                path: '/openAccount',
                name: 'openAccount',
                component: () => import('../views/pages/MoneyManagement/openAccount/openAccount.vue')//开户申请
            },
            {
                path: '/TransferRemittance',
                name: 'TransferRemittance',
                component: () => import('../views/pages/MoneyManagement/TransferRemittance/TransferRemittance.vue')//转账汇款
            },
            {
                path: '/CustomTransfer',
                name: 'CustomTransfer',
                component: () => import('../views/pages/MoneyManagement/CustomTransfer.vue')//转账汇款
            },
            {
                path: '/LedgerRecords',
                name: 'LedgerRecords',
                component: () => import('../views/pages/MoneyManagement/LedgerRecords.vue')//分账记录
            },
        ]
    },

    // 系统管理
    {
        path: '/systemManagement',
        component: Layout,
        name: 'systemManagement',
        children: [
            {
                path: '/OperationDay',
                name: 'OperationDay',
                component: () => import('../views/pages/systemManagement/OperationDay.vue')//操作日记
            },
            {
                path: '/loginDays',
                name: 'loginDays',
                component: () => import('../views/pages/systemManagement/loginDays.vue')//登录日记
            },
            {
                path: '/RolesManagement',
                name: 'RolesManagement',
                component: () => import('../views/pages/systemManagement/RolesManagement.vue')//角色管理
            },
            {
                path: '/staffManagement',
                name: 'staffManagement',
                component: () => import('../views/pages/systemManagement/staffManagement.vue')//员工管理
            },
            {
                path: '/departmentManagement',
                name: 'departmentManagement',
                component: () => import('../views/pages/systemManagement/departmentManagement.vue')//部门管理
            },
            {
                path: '/BankDays',
                name: 'BankDays',
                component: () => import('../views/pages/systemManagement/BankDays.vue')//银行推送日志
            },
            {
                path: '/CodeList',
                name: 'CodeList',
                component: () => import('../views/pages/systemManagement/CodeList.vue')//验证码列表
            },
            {
                path: '/AppVersion',
                name: 'AppVersion',
                component: () => import('../views/pages/systemManagement/AppVersion.vue')//app版本信息
            },
            {
                path: '/AppMessage',
                name: 'AppMessage',
                component: () => import('../views/pages/systemManagement/AppMessage.vue')//app信息管理
            },
            {
                path: '/AppPackRecord',
                name: 'AppPackRecord',
                component: () => import('../views/pages/systemManagement/AppPackRecord.vue')//打包记录
            },
            {
                path: '/PlatformManagement',
                name: 'PlatformManagement',
                component: () => import('../views/pages/systemManagement/PlatformManagement.vue')//平台管理
            },
            {
                path: '/InterestCalculator',
                name: 'InterestCalculator',
                component: () => import('../views/pages/systemManagement/InterestCalculator.vue')//利息计算器
            },
            {
                path: '/officialWebsite',
                name: 'officialWebsite',
                component: () => import('../views/pages/systemManagement/officialWebsite/officialWebsite.vue')//官网
            },
            {
                path: '/wxapplt',
                name: 'wxapplt',
                children: [
                    {
                        path: '/weappltEdit',
                        name: 'weappltEdit',
                        component: () => import('../views/pages/systemManagement/wxapplt/weappltEdit.vue')//小程序设置
                    },
                    {
                        path: '/ReviewandRelease',
                        name: 'ReviewandRelease',
                        component: () => import('../views/pages/systemManagement/wxapplt/ReviewandRelease.vue')//审核和发布
                    },
                    {
                        path: '/messageTemplate',
                        name: 'messageTemplate',
                        component: () => import('../views/pages/systemManagement/wxapplt/messageTemplate.vue')//消息模板
                    },
                ]
            },
            {
                path: '/openUp',
                name: 'openUp',
                component: () => import('../views/pages/systemManagement/openUp')//开发
            },
        ]
    }


]
