<template>
  <router-view/>
</template>
<script setup>
import { onMounted } from 'vue';

  onMounted(()=>{
    document.documentElement.setAttribute('theme', 'light')
  })
</script>
<style lang="less">

</style>
