
import axios from "axios";


let config = {
  // baseURL: "http://test.pubadmin.teesoft.cn",
  baseURL: "https://test.api.orderpay.teesoft.cn",
  // baseURL: "https://api.orderpay.teesoft.cn",
  timeout: 10000, // Timeout
  headers: { "Content-Type": "application/json;charset=UTF-8" },
  
  // withCredentials: true, // Check cross-site Access-Control
};

const request = axios.create(config);
// axios.defaults.baseURL = 'https://test.api.orderpay.teesoft.cn';
axios.defaults.baseURL = 'https://api.orderpay.teesoft.cn';
request.interceptors.request.use(
  function(config) {
		if(sessionStorage.getItem('token')){
			config.data['token'] = sessionStorage.getItem('token');
		}
		// config.data['app_number'] ='pc.teesoft.cn'
		config.data['app_number'] ='test.pc.qiuxin.tech'
		config.data['is_app'] = true;
		config.data['platform'] = 3;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  function(response) {
    const res = response.data
		if (res.code === 200) {
			return res.data;
		} else {
			return Promise.reject(res.msg||res.message)
		}
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default request;

