import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/home/indexView.vue'
import { allroutes } from "./routes";
// import LoginView from '../views/login/loginView'
// import { ElMessage } from 'element-plus'
const routes = allroutes

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
// 路由守卫
router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem('token')


  if (to.path === '/login') {
    next()
    return
  } else {
    // 判断是否登录
    if (!token) {
      next('/login')
      // ElMessage.error('请先登录')
    } else {
      next()
    }
  }
})
export default router
